.bt {
  @include btn-reset;
  &-normal {
    color: #fff;
    background: $brand-primary;
    box-shadow: 5px 5px 0 $brand-primary3;
    padding: 0.76em 3.2em;
    letter-spacing: 0.1em;
    transition: 0.3s;
    position: relative;
    top: 0;
    left: 0;
    &.bg2 {
      background: $brand-primary2;
      box-shadow: 5px 5px 0 darken($brand-primary2,50%);
    }
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      background: url(../img/btn_arrow.png) no-repeat;
      width: 13px;
      height: 23px;
      margin: -0.1em 0 0 1em;
    }
    &:hover {
      top: 5px;
      left: 5px;
      box-shadow: 0 0 0 $brand-primary3;
    }
  }
}

@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
  .bt {
    &-normal {
      @include fz(24px);
    }
  }
}
@media (min-width: $screen-lg-min) {
}
