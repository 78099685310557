.header {
  position: relative;
  .container {
    max-width: 1794px;
  }
  .site-title {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: linear-gradient(to right, #124a75 0%,#09253b 100%);
    box-shadow: 0 2px 4px rgba(#000,0.13);
    padding: 7px 60px 7px 15px;
    margin: 0;
    font-size: 1em;
    .logo {
      @include txt-hide;
      vertical-align: top;
      background-image: url(../img/logo.svg);
      background-size: contain;
      width: 151px;
      height: 46px;
    }
  }
  .follow {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
    background: $brand-primary2;
    .list {
      display: table;
      width: 100%;
      table-layout: fixed;
      li {
        display: table-cell;
        vertical-align: middle;
        a {
          display: block;
          height: 40px;
          line-height: 40px;
          i {
            display: none;
          }
          &::before {
            content: '';
            display: inline-block;
            vertical-align: top;
            width: 40px;
            height: 40px;
            background: url(../img/follow_icons.svg) no-repeat;
          }
          &:hover {
            background: $brand-primary;
          }
        }
      }
      li.fb {
        a {
          &::before {
            background-position: -40px 0;
          }
        }
      }
      li.line {
        a {
          &::before {
            background-position: -80px 0;
          }
        }
      }
      li.phone {
        a {
          &::before {
            background-position: 0 0;
          }
        }
      }
    }
  }
}
.nav {
  position: fixed;
  z-index: 250;
  top: 60px;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: 0.3s;
  .nav-open & {
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-open {
    overflow: hidden;
  }
  &-toggle {
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 250;
    top: 0;
    right: 0;
    .ic-bar {
      background: #fff;
      width: 20px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -10px;
      transform-origin: center;
      transition: 0.3s;
      &:nth-of-type(1) {
        transform: translateY(-8px);
      }
      &:nth-of-type(2) {
        transform: translateY(8px);
      }
    }
    .nav-open & {
      .ic-bar {
        &:nth-of-type(1) {
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(3) {
          display: none;
        }
      }
    }
  }
  &-content {
    padding: 10px 0;
    background: $brand-primary;
    box-shadow: 0 2px 4px rgba(#000,0.13);
  }
  .menu {
    @include fz(16px);
    text-align: center;
    letter-spacing: 0.1em;
    padding: 1em 0;
    color: #fff;
    > li {
      > a {
        display: block;
        color: inherit;
        position: relative;
        padding: 0.5em;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    transition: top 0.3s;
    background: linear-gradient(to right, #124a75 0%,#09253b 100%);
    @include clearfix;
    .container {
      max-width: 1580px;
    }
    .site-title {
      position: static;
      background: none;
      box-shadow: none;
      float: left;
      padding: 8px 0 10px;
      .logo {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .follow {
      background: none;
      position: absolute;
      left: auto;
      right: 10px;
      bottom: auto;
      top: 16px;
      .list {
        display: block;
        width: auto;
        li {
          display: inline-block;
          vertical-align: middle;
          a {
            padding: 5px;
            height: 34px;
            width: 34px;
            line-height: 22px;
            border-radius: 2px;
            @include fz(24px);
            i {
              display: block;
              vertical-align: top;
              margin-top: -1px;
            }
            &::before {
              display: none;
            }
            &:hover {
              background: #fff;
            }
          }
        }
        li.fb {
          a {
            &:hover {
              color: #3A5893;
            }
          }
        }
        li.line {
          a {
            &:hover {
              color: #00b900;
            }
          }
        }
        li.phone {
          display: none;
        }
      }
    }
  }
  .nav {
    position: static;
    float: right;
    &-toggle {
      display: none;
    }
    &-content {
      background: none;
      box-shadow: none;
      padding: 0;
      margin-right: 75px;
    }
    .menu {
      padding: 5px 0 0;
      margin: 0 -10px;
      li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        a {
          padding: 1em 0;
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 0;
            height: 2px;
            background: $brand-primary2;
            transition: 0.3s;
          }
          &:hover {
            color: $brand-primary2;
            &::after {
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .header {
    .follow {
      right: 25px;
    }
  }
  .nav {
    &-content {
      margin-right: 105px;
    }
    .menu {
      margin: 0 -20px;
      li {
        padding: 0 20px;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
}
