.list,
.list-h,
.list-v,
.list-inline {
  &,
  ul {
    @include list-reset;
  }
  a, .box {
    display: block;
    text-decoration: none;
    color: inherit;
    span {
      display: block;
    }
  }
}
.list-h {
  @include clearfix;

  > li,
  .item {
    float: left;
  }
}
.list-inline {
  > li {
    display: inline-block;
    vertical-align: top;
  }
  a {
    display: inline-block;
  }
}
.list-normal {
  @include list-reset;
  li {
    position: relative;
    padding-left: 1.5em;
    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $text-color;
      position: absolute;
      left: 0;
      top: $line-height-base * 0.5 + 0em;
      margin-top: -6px;
    }
  }
}