.sect {
  padding: 4.5em 0;
  &-title {
    color: $brand-primary3;
    @include fz(20px);
    text-align: center;
    letter-spacing: 0.1em;
    margin: 0 0 1.2em;
    &.c2 {
      color: #fff;
    }
  }
  &-bt-box {
    text-align: center;
    padding-top: 2em;
  }
}
.main {
  .banner {
    padding: 0;
    .item {
      background: 50% 0 no-repeat;
      background-size: cover;
      height: 340px;
      position: relative;
      .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 86%;
        max-width: 310px;
        transform: translate(-50%,-50%);
        color: #fff;
        background: rgba(#000,0.3);
        text-align: center;
        line-height: (90 / 50);
        padding: 1.2em 1em;
        &::before,
        &::after {
          content: '';
          display: block;
          left: 0.5em;
          right: 0.5em;
          position: absolute;
          height: 2px;
          background: $brand-primary2;
        }
        &::before {
          top: 0.5em;
        }
        &::after {
          bottom: 0.5em;
        }
        span {
          display: inline-block;
          text-align: left;
        }
      }
    }
    .slick-dots {
      @include list-reset;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 86%;
      max-width: 310px;
      transform: translateX(-50%);
      margin-top: 2.5em;
      text-align: right;
      li {
        display: inline-block;
        vertical-align: middle;
        button {
          @include btn-reset;
          @include txt-hide;
          width: 24px;
          height: 24px;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -4px 0 0 -4px;
          }
        }
      }
      li.slick-active {
        button {
          &::before {
            background: $brand-primary2;
          }
        }
      }
    }
  }
  .loan {
    &-bike,
    &-car {
      display: block;
      position: relative;
      padding: 1em 0;
      @include fz(14px);
      .title {
        color: #fff;
        line-height: (27 / 19);
        background: $brand-primary;
        position: absolute;
        top: 0;
        width: 6.6em;
        padding: 0.8em 1.2em;
        &::after {
          content: '';
          display: block;
          background: $brand-primary2;
          width: 2.8em;
          height: (5 / 19) + 0em;
          margin-top: 0.5em;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          width: 100%;
          width: 0;
          height: 0;
          border-style: solid;
        }
      }
    }
    &-bike {
      .title {
        right: calc(6.6% + 1em);
        &::before {
          right: 0;
          border-width: 0 6.6em 3em 0;
          border-color: transparent $brand-primary transparent transparent;
        }
      }
    }
    &-car {
      .title {
        left: 1em;
        &::before {
          left: 0;
          border-width: 3em 6.6em 0 0;
          border-color: $brand-primary transparent transparent transparent;
        }
      }
    }
  }
  .why {
    background: url(../img/why_bg.png) 50% 50% no-repeat;
    background-size: cover;
    &-list {
      counter-reset: num;
      text-align: center;
      .item {
        position: relative;
        counter-increment: num;
        padding-top: 0.95em;
        &::before {
          content: counter(num);
          position: absolute;
          z-index: 10;
          top: 0;
          left: 50%;
          width: 2.6em;
          height: 2.6em;
          line-height: 2.6em;
          margin-left: -1.3em;
          background: #757575;
          color: #fff;
        }
        .box {
          position: relative;
          background: #fff;
          max-width: 300px;
          margin: 0 auto 1.5em;
          padding: 4em 2em 3.5em;
          &::before {
            content: '';
            display: block;
            border: 1px solid #757575;
            position: absolute;
            top: 0.6em;
            bottom: 0.6em;
            left: 0.6em;
            right: 0.6em;
            pointer-events: none;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 4.4em;
            height: 2em;
            margin-left: -2.2em;
            background: #fff;
          }
        }
      }
      .item:hover {
        &::before {
          background: $brand-primary2;
        }
        .box {
          &::before {
            border-color: $brand-primary2;
          }
          .title {
            color: $brand-primary;
          }
        }
      }
    }
  }
  .difficult {
    &-list {
      text-align: center;
      .box {
        position: relative;
        max-width: 435px;
        margin: 0 auto 1em;
        .title {
          color: #fff;
          padding: 2em 0.5em;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba($brand-primary3,0.5);
          box-shadow: inset 0 -1em 2em rgba(#000,0.3);
          transition: 0.3s;
        }
        .ic {
          display: inline-block;
          vertical-align: text-bottom;
          background: url(../img/difficult_icons.png) no-repeat;
          background-size: auto 200%;
          width: 2em;
          height: 2em;
          margin: -0.4em 1em -0.2em 0;
          &.ic-head {
            background-position: 0 0;
          }
          &.ic-money {
            background-position: 50% 0;
          }
          &.ic-bank {
            background-position: 100% 0;
          }
        }
      }
      .box:hover {
        .title {
          background: $brand-primary;
          color: $brand-primary2;
          box-shadow: none;
        }
        .ic {
          &.ic-head {
            background-position: 0 100%;
          }
          &.ic-money {
            background-position: 50% 100%;
          }
          &.ic-bank {
            background-position: 100% 100%;
          }
        }
      }
    }
  }
  .step {
    padding: 0;
    .sect-title {
      margin-bottom: 2em;
    }
    &-bg {
      padding: 3.5em 0 4.5em;
      background: url(../img/step_bg.png) 50% 50% no-repeat;
      background-size: cover;
    }
    &-list {
      text-align: center;
      .item {
        + .item {
          &::before {
            content: '';
            display: block;
            background: url(../img/step_arrow.png) no-repeat;
            background-size: contain;
            width: (25 * 0.6) + 0px;
            height: (47 * 0.6) + 0px;
            transform: rotate(90deg);
            margin: 1.5em auto 1em;
          }
        }
      }
      .box {
        max-width: 180px;
        margin: 0 auto 1em;
        background: #fff;
        position: relative;
        padding: 1.2em 1em;
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: -5px;
          bottom: -5px;
          width: 100%;
          height: 100%;
          border: 1px solid $brand-primary2;
          border-width: 0 1px 1px 0;
        }
        img {
          width: 80px;
        }
        .title {
          padding: 0.5em 0 0;
        }
      }
    }
  }
  .service {
    &-list {
      text-align: center;
      @include clearfix;
      .item {
        float: left;
        width: 50%;
        padding: 0 0.5em;
        border-bottom: 1px solid $brand-primary;
      }
      .box {
        padding: 1em 0 1.5em;
        img {
          width: 100px;
        }
      }
    }
  }
  .case {
    padding-top: 2em;
    &-list {
      .box {
        background: #fff;
        border: 1px solid #D4CECE;
        padding: 1.5em;
        max-width: 440px;
        margin: 0 auto 1.5em;
        .pic {
          margin-bottom: 1em;
          overflow: hidden;
          img {
            transition: 0.3s;
          }
        }
        .date,
        .title {
          color: $brand-primary;
        }
        .date {
          letter-spacing: 0.1em;
        }
        .title {
          @include multi-line(2);
          @include fz(18,16);
          font-weight: 500;
          line-height: 1.4;
          margin: 0.2em 0 0.8em;
        }
        .desc {
          @include multi-line(5);
        }
      }
      .box:hover {
        border-color: $brand-primary2;
        .pic {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .sect {
    padding: 6em 0;
    &-title {
      margin-bottom: 2em;
    }
    &-bt-box {
      padding-top: 3em;
    }
  }
  .main {
    .banner {
      .item {
        height: auto;
        padding-top: percentage(836 / 1920);
        .txt {
          @include fz(20px);
          max-width: 400px;
        }
      }
      .slick-dots {
        max-width: 400px;
        margin-top: 3.5em;
        padding: 0 0.3em;
      }
    }
    .loan {
      margin: 0 -6%;
      &-bike,
      &-car {
        display: inline-block;
        .title {
          @include fz(17px);
        }
      }
      &-bike {
        width: percentage(757 / 1390);
      }
      &-car {
        position: relative;
        top: 6em;
        margin-left: percentage(-17 / 1390);
        width: percentage(604 / 1390);
      }
    }
    .why {
      &-list {
        @include clearfix;
        margin: 0 -0.8em;
        .item {
          float: left;
          width: 33.33%;
          padding-left: 0.8em;
          padding-right: 0.8em;
          .box {
            margin-bottom: 0;
            max-width: 220px;
            padding: 5em 1.5em 4.5em;
          }
        }
      }
    }
    .difficult {
      &-list {
        @include clearfix;
        margin: 0 -0.8em;
        .item {
          float: left;
          width: 33.33%;
          padding: 0 0.8em;
        }
        .box {
          margin-bottom: 0;
        }
      }
    }
    .step {
      .sect-title {
        margin-bottom: 3em;
      }
      &-bg {
        padding: 3.5em 0 5em;
      }
      &-list {
        @include clearfix;
        margin: 0 -1.5em;
        .item {
          width: 25%;
          float: left;
          padding: 0 1.5em;
          position: relative;
          + .item {
            &::before {
              position: absolute;
              transform: translate(-40%,-50%);
              top: 50%;
              left: 0;
              margin: 0;
            }
          }
        }
        .box {
          margin-bottom: 0;
        }
      }
    }
    .service {
      &-list {
        .item {
          width: 25%;
        }
        .box {
          .title {
            padding-top: 0.5em;
          }
        }
      }
    }
    .case {
      &-list {
        @include clearfix;
        margin: 0 -0.8em;
        .item {
          float: left;
          width: 33.33%;
          padding: 0 0.8em;
        }
        .box {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .sect {
    &-title {
      @include fz(30px);
    }
    &-bt-box {
      padding-top: 3.8em;
    }
  }
  .main {
    .banner {
      .item {
        .txt {
          @include fz(30px);
          max-width: 600px;
        }
      }
      .slick-dots {
        max-width: 600px;
        margin-top: 6em;
        padding: 0 0.6em;
      }
    }
    .loan {
      padding: 7.5em 0 10em;
      .container {
        max-width: 1520px;
      }
      &-bike,
      &-car {
        .title {
          @include fz(21px);
        }
        img {
          width: 100%;
        }
      }
      &-car {
        top: 8em;
      }
    }
    .why {
      background-attachment: fixed;
      .container {
        max-width: 1090px;
      }
      &-list {
        @include fz(20px);
        margin: 0 -1.2em;
        .item {
          padding-left: 1.2em;
          padding-right: 1.2em;
          .box {
            max-width: 312px;
            padding: 5.5em 1.5em 5.2em;
          }
        }
      }
    }
    .difficult {
      padding: 10em 0;
      &-list {
        .box {
          .title {
            @include fz(18px);
          }
        }
      }
    }
    .step {
      &-bg {
        padding: 7em 0 10em;
        background-attachment: fixed;
      }
      &-list {
        .box {
          max-width: 190px;
          padding: 1.8em 1em;
          &::after {
            right: -10px;
            bottom: -10px;
          }
          .title {
            @include fz(20px);
          }
        }
      }
    }
    .service {
      padding: 10em 0;
      .container {
        max-width: 1240px;
      }
      &-list {
        .box {
          padding: 2em 0 2.5em;
          img {
            width: auto;
          }
          .title {
            @include fz(19px);
            letter-spacing: 0.1em;
          }
        }
      }
    }
    .case {
      padding-bottom: 10em;
      &-list {
        margin: 0 -1em;
        .item {
          padding: 0 1em;
        }
        .box {
          .desc {
            line-height: (30 / 16);
            -webkit-line-clamp: 8;
            max-height: (30 / 16 * 8) + 0em;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .main {
    .banner {
      .item {
        .txt {
          @include fz(40px);
          max-width: 750px;
        }
      }
      .slick-dots {
        max-width: 750px;
        margin-top: 8em;
        padding: 0 1em;
      }
    }
    .step {
      // &-bg {
      //   padding: 12em 0 14em;
      // }
      &-list {
        .item {
          + .item {
            &::before {
              width: 25px;
              height: 47px;
            }
          }
        }
        .box {
          max-width: 245px;
          padding: 2.2em 1em;
          img {
            width: auto;
          }
          .title {
            @include fz(24px);
          }
        }
      }
    }
    .case {
      &-list {
        .box {
          padding: 2em;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .main {
    .banner {
      .item {
        .txt {
          @include fz(50px);
          max-width: 945px;
        }
      }
      .slick-dots {
        max-width: 945px;
        margin-top: 10.2em;
        padding: 0 1.2em;
      }
    }
  }
}
