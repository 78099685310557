@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

@import "helpers/variables";
@import "node_modules/animatewithsass/animate.scss";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "helpers/mixins";
@import "helpers/normalize";
@import "helpers/basic";
@import "helpers/animate";

@import "components/list";
// @import "components/tables";
// @import "components/forms";
@import "components/buttons";
// @import "components/breadcrumbs";
// @import "components/pagination";

@import "layouts/header";
@import "layouts/footer";
@import "layouts/main";

@import "pages/index";
