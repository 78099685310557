.footer {
  @include fz(15px);
  &-title {
    border-bottom: 3px solid $brand-primary2;
    @include fz(21,15);
    padding: 0.4em 15px;
    margin: 0 0 0.6em;
  }
  &-top {
    background: url(../img/footer_bg.png) 50% 50% no-repeat;
    background-size: cover;
    color: #fff;
    padding: 4em 0 2em;
    &-sect {
      margin-bottom: 3em;
    }
    .footer-about {
      &-txt {
        padding: 0 15px;
        margin: 0;
      }
    }
    .footer-contact {
      &-list {
        padding: 0 15px;
        li {
          position: relative;
          padding-left: 2em;
          margin-bottom: 0.8em;
          &::before {
            content: '';
            display: block;
            background: url(../img/footer_icons.png) no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
          }
          &.phone {
            &::before {
              background-position: 0 0;
            }
          }
          &.time {
            &::before {
              background-position: 0 50%;
            }
          }
          &.addr {
            &::before {
              background-position: 0 100%;
            }
          }
          a {
            display: inline-block;
            &:hover {
              color: $brand-primary2;
            }
          }
        }
      }
    }
    .footer-service {
      &-list {
        padding: 0 15px;
      }
    }
  }
  &-bottom {
    padding: 1em 15px;
    .copyright {
      text-align: center;
      margin: 0;
    }
  }
}

.floating {
  position: fixed;
  z-index: 200;
  bottom: 100px;
  right: 0;
  .gotop {
    @include txt-hide;
    background: $brand-primary2 url(../img/gotop.png) 50% 50% no-repeat;
    background-size: contain;
    width: 42px;
    height: 42px;
    &:hover {
      background-color: $brand-primary;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    padding-bottom: 0;
    .container {
      max-width: 1000px;
    }
    &-title {
      border-bottom: 0;
      margin-bottom: 1.2em;
    }
    &-top {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 7.8em;
        left: 0;
        right: 0;
        border-top: 3px solid $brand-primary2;
      }
      &-sect {
        width: 39%;
        float: left;
      }
      .footer-service {
        width: 22%;
      }
    }
    &-bottom {
      padding: 2em 0;
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
    &-top {
      &-sect {
        width: 40%;
      }
      .footer-contact-list,
      .footer-about-txt {
        max-width: 280px;
      }
      .footer-service {
        width: 20%;
      }
    }
  }
  .floating {
    bottom: 20%;
    right: 20px;
    .gotop {
      width: 52px;
      height: 52px;
      box-shadow: 5px 5px 10px rgba(#000,0.16);
    }
  }
}
@media (min-width: $screen-lg-min) {
  .footer {

  }
}
