
@media screen and (min-width: $screen-sm-min)  {
  .anime {
    visibility: hidden;
    &.anime-run {
      visibility: visible;
    }
  }
  .main {
    .loan-bike.anime-run {
      @include fadeInLeft($duration: 0.5s, $delay: 0.2s);
    }
    .loan-car.anime-run {
      @include fadeInRight($duration: 0.5s, $delay: 0.5s);
    }
  }
  .why-list,
  .case-list {
    .item.anime-run {
      @include fadeInUp($duration: 0.5s);
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2 - 0.1}s;
        }
      }
    }
  }
  .difficult-list,
  .service-list {
    .item.anime-run {
      @include fadeIn($duration: 0.5s);
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2 - 0.1}s;
        }
      }
    }
  }
  .step-list {
    .item.anime-run {
      @include fadeInLeft($duration: 0.5s);
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2 - 0.1}s;
        }
      }
    }
  }
  .sect2-content-txt.anime-run {
    @include zoomIn($duration: 0.5s, $delay: 0.2s);
  }
  .sect6-content {
    .pic,
    .txt,
    .bt {
      @include fadeIn($duration: 0.5s);
    }
    .pic {
      animation-delay: 0.15s;
    }
    .txt {
      animation-delay: 0.3s;
    }
    .bt {
      animation-delay: 0.45s;
    }
  }
}
